import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">福大同<a href="https://www.facebook.com/fulldonetea" className="location" target="_blank">↗</a></h1>
          <h6 className="speaker">店主/蔡子晴　筆者/林立青</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_5_a">
            <div className="column_4">
              <div className="context">
                <h5 className="main">福大同茶莊是目前萬華最老的商號之一，經營茶葉買賣已有180年歷史。</h5>
                <p>從一開始落腳艋舺焙茶供應街坊的文化傳統，而今傳統延續精神不變，作為貴陽街上最老的茶行，供應著社區內正德佛堂的信眾飲用黃金蜜香烏龍，過去祖先以茶為藥分享給街坊；現在的福大同作為茶人，福大同依舊選擇用最好的茶品供應社群，第六代的蔡子晴則選擇用家族的密傳茶葉蛋供應當地社福團體的送餐活動，在艋舺的人們是幸福的，無論貧富能力，都有機會品味福大同的茶人專業文化傳承。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_b">
            <div className="column_8">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pPkNiSsoT-Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_c">
            <div className="column_6">
              <div className="context">
                <p>很多人知道台北起源於艋舺，艋舺的第一條街就是貴陽街，然而很少人知道這裡有一家商號已經傳續六代，長年來在艋舺守著一縷茶香。在21世紀的現代台灣，我們很難想像的到什麼事業能夠祖傳六代，福大同卻做到了。當你來訪時，能在老闆熱情地接待下，從茶盤裡面，聞過香杯、順過喉舌，在餘香依舊時去體會茶香和產地的差異，這裡的茶不燥不火，總等到茶性轉溫以後，才供應上架，這些培火工藝保存下來的智慧如同牆上的照片般，見證台灣品茶的歷史文化。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_d">
            <div className="column_6">
              <div className="context">
                <p>福大同不只在萬華飄出茶香，也在此開啟養生智慧，一府二鹿三艋舺的輝煌年代，商旅絡繹不絕，第一代太醫出身的蔡漢選擇落腳於貴陽街焙茶品茗，當時醫療條件缺乏，水質堪慮，蔡漢以茶為藥為街坊鄰居治病，商號名聲因此傳開，也在貴陽街穩定經營至今，店內現仍珍藏著過去貴陽街舊名「廈新街」的刻印和各種珍貴老照片，福大同是一部活歷史，保留著屬於艋舺的數代茶人傳奇。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_e">
            <div className="column_3">
              <div className="context">
                <p>第五代的蔡玄甫已有50年的經驗，牆面上掛著台灣的地圖及海拔註記，對應著架上的茶葉產地，店內標語「新焙舊烘，百年藏紅」隱喻著店家從烘茶工藝出發回饋鄉里的起家故事，茶人世家的代代智慧相傳，如今是各地學校紛紛前來拜師的製茶名家。</p>
              </div>
            </div>
            <div className="column_3">
              <div className="context">
                <p>一百八十年來，從第一代至今，福大同茶莊烘焙著台灣的珍稀茶品文化，每日依舊清晨起來控溫熟成，斟酌天候、思量濕度，在焙籠間繞著茶人的身影和對於當地社群的連結，而今不管是廟宇聚會時的茶品和無家者年中的送餐，聖誕晚會的供應，也能嘗到這百年商號的福澤。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_f">
            <div className="column_7">
              <div className="context">
                <h5>現在的福大同依舊像是那當年渡海來台的蔡漢一樣，以茶人之心聆聽且回饋於艋舺。</h5>
              </div>
            </div>
          </section>
        </div>

        <div className="page_footer">
          <Link to="/project_a_gift" className="next_page">
            <div className="next_title">《魏絲貝斯計畫》</div>
            <div className="next_owner">何孟娟</div>
          </Link>
        </div>

      </div>
    )

  }

}


export default Page

